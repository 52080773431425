import styled from '@emotion/styled'
import React from 'react'

const NoWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minHeight: '150px',
    justifyContent: 'center',
    "& .content":{
        fontSize: '20px',
        color: 'var(--colorThemePrimary)',
        fontWeight: '600',
      }
})


const NoEvents = () => {
  return (
    <NoWrapper>
        <div className="image-wrapper">
            <img src="https://res.cloudinary.com/ddkdvh5ps/image/upload/w_65,h_65/v1684322441/ezt-frontend-client-assets/question_b89zgh.png"
                alt="No Event Found - Eazyticks"
                loading="lazy"/>
        </div>
        <div className="content">
            No Events Found!
        </div>
    </NoWrapper>
  )
}

export default NoEvents