import React, { createContext, useEffect, useReducer, ReactNode, useRef } from 'react'
import { useCart } from 'react-use-cart'
import { useNavigate } from 'react-router-dom'
import { getUserLocation } from '../helpers/UserLocation'
import { LoginUser, cancelTheOrder, completeTheOrder, placeTheOrder } from '../helpers/businessRequests'
import { getExtrafieldsFilteredOrder, getFilteredOrder, hasExtraFields } from '../helpers/generals'
import { getConnection } from '../helpers/signalR'

interface statetypes {
    connection: any,
    user:  any,
    isLoading: boolean,
    isLoggedIn: boolean,
    activeStep: string,
    steps: string[],
    clientSecret: string | undefined | null,
    orderId: string | undefined,
    orderPlaced: boolean,
    orderPlacedMessage: string,
    email: string | null,
    toaster: {
        open: boolean,
        type: string | null,
        message: string | null
    },
    location: any
}

const initialState : statetypes = {
  connection: null,
  user: null,
  isLoading: true,
  isLoggedIn: false,
  activeStep: 'tickets',
  steps : [],
  clientSecret: undefined,
  orderId: undefined,
  orderPlaced: false,
  orderPlacedMessage: '',
  email: null,
  toaster: {
    open: false,
    type: '',
    message: ''
  },
  location: null
}

const reducer = (state:any, action:any) => {
    switch (action.type) {
      case "INIT_BOOKINGS":
        return { 
            ...state,
            steps: action.payload.steps,
            activeStep: action.payload.steps[0]
        }
      case "CHANGE_STEP":
        return {
            ...state,
            activeStep: action.payload
        }
      case "CHANGE_LOCATION":
        return {
            ...state,
            location: action.payload
        }
      case "SET_CONNECTION":
        return {
            ...state,
            connection: action.payload
        }
      case "SET_TOASTER":
        const {open,type,message} = action.payload
        return {
            ...state,
            toaster: {
                open,
                type: type ? type : state.toaster.type,
                message: message ? message : state.toaster.message
            }
        }
      case "SET_CLIENT_SECRET":
        return {
            ...state,
            clientSecret: action.payload
        }
      case "SET_ORDER_ID":
        return {
            ...state,
            orderId: action.payload
        }
      case "SET_EMAIL":
        return {
            ...state,
            email: action.payload
        }
      case "SET_USER":{
        const {user,isLoggedIn} = action.payload
        if(user) window.localStorage.setItem('posUser',JSON.stringify(user));
        return {
            ...state,
            user,
            isLoggedIn
        }
      }
      case "SET_ORDER_PLACED":
        return {
            ...state,
            orderPlaced: action.payload
        }
      case "SET_ORDER_PLACED_MESSAGE":
        return {
            ...state,
            orderPlacedMessage: action.payload
        }
      default: {
          return { ...state }
      }
    }
}

const AppContext = createContext({
    ...initialState,
    // addStepOne: () => Promise.resolve(),
    logIn: (email: string,password: string) => Promise.resolve(),
    logout: () => null,
    changeStep: (name: any) => Promise.resolve(),
    placeOrder: (event:any,handlePlaceOrder:any,terminal:any) => Promise.resolve(),
    completeOrder: (event:any) => Promise.resolve(),
    cancelOrder: () => Promise.resolve(),
    initBookings: (event:any) => Promise.resolve(),
    changeEmail: (email:any) => null,
    setClientSecret: (secret:any) => null,
    setOrderPlaced: (placed:any) => null,
    setUser: (user:any,isLoggedIn:boolean) => null,
    setConnection: () => Promise.resolve(),
    setToaster: (open:boolean,type:string,message:string) => null,
    setOrderPlacedMessage: (message: any) => null,
    setPreciseLocation: () => null
})

interface Props {
    children?: ReactNode
    // any props that come into the component
}

export const AppProvider = ({ children } : Props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const {cartTotal,items} = useCart()
    const navigate = useNavigate()
    const didMount = useRef(false);


    function setUser(user:any,isLoggedIn:boolean){
        dispatch({
            type:"SET_USER",
            payload: {user,isLoggedIn}
        })
    }

    async function logIn(email:any,password:any){
        try {
            const { data } = await LoginUser(email,password)
            if(data?.succeeded === true){
                setUser(data?.data,true)
                navigate('/')
            }else{
                console.log("Error>>",data)
            }
            return Promise.resolve(data)
        } catch (error) {
            console.log("Error",error)
            return Promise.reject('Failed to login!')
        }
    }

    function logout(){
        window.localStorage.removeItem('posUser')
        setUser(null,false)
    }

    async function initBookings(event:any){
        const steps = calculateSteps(event);
        dispatch({
            type: 'INIT_BOOKINGS',
            payload: {event,steps},
        })
    }

    // async function getConnection(){
    //     if(!state.user) return;
    //     try {
    //         const connection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/OrderNotificationHub',{
    //             accessTokenFactory: () => {
    //               return state.user.token
    //             }
    //           }).withAutomaticReconnect().configureLogging(LogLevel.Information).build();
              
    //           connection.onclose(e => {
    //             console.log("Is closing connection")
    //           })
    //           await connection.start();
    //           return connection
    //     } catch (error) {
    //         console.log("Error",error)
    //     }
    // }

    async function setConnection() {
        let connection = await getConnection(state.user)
        dispatch({
            type: "SET_CONNECTION",
            payload: connection
        })
    }

    function changeStep(name:any){
        dispatch({
            type: 'CHANGE_STEP',
            payload: name
        })
    }

    function setToaster(open:boolean,type:string,message:string){
        dispatch({
            type: 'SET_TOASTER',
            payload: {
                open,type,message
            }
        })
    }

    function setClientSecret(secret:any){
        dispatch({
            type: 'SET_CLIENT_SECRET',
            payload: secret
        })
    }

    function setOrderId(id:any){
        dispatch({
            type: 'SET_ORDER_ID',
            payload: id
        })
    }

    function setOrderPlaced(placed:any){
        dispatch({
            type: 'SET_ORDER_PLACED',
            payload: placed
        })
    }

    async function setPreciseLocation(){
        try {
            const data = await getUserLocation()
            dispatch({
                type: "CHANGE_LOCATION",
                payload: data
            })
            localStorage.setItem('locObject',JSON.stringify(data))
        } catch (error) {
            console.error("Error",error)
        }
    }

    function setOrderPlacedMessage(message:any){
        dispatch({
            type: 'SET_ORDER_PLACED_MESSAGE',
            payload: message
        })
    }

    function changeEmail(email:any){
        dispatch({
            type: 'SET_EMAIL',
            payload: email
        })
    }

    function calculateSteps(event:any){
        let steps = []
        if(event?.tickets && event?.tickets?.length > 0) steps.push('tickets')
        if(event?.parkings && event?.parkings?.length > 0) steps.push('parking')
        if(event?.eventShopItems && event?.eventShopItems?.length > 0) steps.push('shop')
        if(event?.deals && event?.deals?.length > 0) steps.push('deals')
        return steps
    }

    async function placeOrder(event:any,extraFields:any,terminal:any){
        let order:any = {};
        if(hasExtraFields(items) && extraFields){
            order = getExtrafieldsFilteredOrder(event,extraFields.fields,state.email,cartTotal)
        }else{
            order = getFilteredOrder(event,items,state.email,cartTotal)
        }

        order.terminalId = terminal?.id

        try {
            const { data } = await placeTheOrder(order)
            const { status, object:orderId, message } = data
            if(orderId) setOrderId(orderId)
            if(status === 'Success'){
                return Promise.resolve(data)
            }else if(status === 'Error'){
                return Promise.resolve(data)
            }
        } catch (error) {
            console.log('Error',error)
            return Promise.reject(error)
        }
    }

    async function completeOrder(paymentIntentId:any,orderId?:any){
        return await completeTheOrder(paymentIntentId,orderId ? orderId : state.orderId)
    }

    async function cancelOrder() {
        if(!state.orderId) return
        return await cancelTheOrder(state.orderId)
    }

    function onStorageUpdate(e:any){
        const { key, newValue } = e;
        if (key === "user") {
          setUser(JSON.parse(newValue),true)
        }
    }
    
    useEffect(() => {
        ; (async () => {
            try {

            } catch (err) {
                // dispatch({type: 'INIT'})
            }
        })()
        let oldUser = window.localStorage.getItem('posUser');
        let oldLocation = window.localStorage.getItem('locObject');
        if(oldUser){
            setUser(JSON.parse(oldUser),true)
        }else{
            setUser(null,false)
        }
        if(oldLocation){
            dispatch({
                type: 'CHANGE_LOCATION',
                payload: JSON.parse(oldLocation)
            })
        }

        window.addEventListener("storage", onStorageUpdate);
        return () => {
          window.removeEventListener("storage", onStorageUpdate);
        };
    }, [])

    useEffect(()=>{
        if ( !didMount.current ) {
            didMount.current = true;
            return;
        }
        if(!state.user) navigate('/account/signin') 
    },[state.user])

    return (
        <AppContext.Provider
            value={{
                ...state,
                logIn,
                logout,
                changeStep,
                placeOrder,
                completeOrder,
                cancelOrder,
                changeEmail,
                initBookings,
                setClientSecret,
                setOrderPlaced,
                setOrderPlacedMessage,
                setToaster,
                setPreciseLocation,
                setConnection
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext