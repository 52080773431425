import React from 'react';
import { Box, styled } from '@mui/material'
import SectionHeading from '../../Common/SectionHeading';
import SectionLoader from '../../Common/Loaders/SectionLoader';
import NoEvents from './Components/NoEvents';
import EventCard from './Components/EventCard';

const SectionWrapper = styled('section')({
  padding: '3em 0em',
  "& .section__heading":{
    maxWidth: '1320px',
    paddingInline: '1em',
    margin: 'auto auto 2em',
    "@media(max-width:768px)":{
      padding: '0em 1em'
    }
  }
})

const SectionContent = styled(Box)({
  position: 'relative',
  maxWidth: '1300px',
  padding: '0em 4.2em',
  margin: 'auto',
  "@media(max-width:768px)":{
    padding: '0em 1.4em'
  },  
  "& .category-single-slide":{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '1em',
    "& > div":{
      width: '100%'
    }
  },
  '& .btn--next,& .btn--prev': {
    position: 'absolute',
    top: '50%',
    padding: '10px',
    width: 'fit-content',
    transform: 'translateY(-50%)',
    background: 'transparent',
    color: 'var(--colorThemePrimary)',
    "@media(max-width:768px)":{
      padding: '5px',
    },
    "& svg":{
      fontSize: 'unset',
      width: '50px',
      height: '50px',
      fill: 'currentColor',
      "@media(max-width:768px)":{
        width: '30px',
        height: '30px',
      },
    },
  },
  '& .btn--next': {
    right: '0',
  },
  '& .btn--prev': {
    left: '0',
  }
})

interface FunctionProps {
  items: any,
  isLoading: boolean
}

function EventListing(props:FunctionProps) {
  const swiperRef = React.useRef<any | null>(null)

  return (
    <SectionWrapper>
      <SectionHeading>Active Events</SectionHeading>
      <SectionContent>
      { props.isLoading ? 
          <SectionLoader /> : (
            props.items.length > 0 ? (
              <>
              {props.items.map((event:any,i:any)=>{
                return <EventCard event={event} key={i} />
              })}
            </>
            ) : (
                <NoEvents/>
            )
          ) }
      </SectionContent>
      
    </SectionWrapper>
  )
}

export default EventListing