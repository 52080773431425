import axios from 'axios';

const axiosAuthRequest = axios.create({});

axiosAuthRequest.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('posUser'));


  if (user) {
    config.headers.Authorization = `Bearer ${user?.token}`;
  }

  return config;
});

// Add a response interceptor
axiosAuthRequest.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log("Interceptor response",response)
  return response;
}, function (error) {
  if(error?.response?.status === 401){
    window.localStorage.removeItem('posUser')
    window.location.href = '/'
    window.location.reload()
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosAuthRequest;