import * as htmlToImage from 'html-to-image'
import moment from 'moment'
import { checkOrderStatus, sendTheReceipt } from './businessRequests'
import Swal from 'sweetalert2'

// Add Postfixes to the cart ID's - Start

let ticketPf = '1', parkingPf = '2', shopPf = '3', dealsPf = '4'

export function AddIdPostFix(id,type){
  switch (type) {
    case 'tickets':
      return parseInt(id + ticketPf)
    case 'parkings':
      return parseInt(id + parkingPf)
    case 'shop':
      return parseInt(id + shopPf)
    case 'deal':
      return parseInt(id + dealsPf)
    default:
      return id
  } 
}

export function removeIdPostFix(id,type){
  switch (type) {
    case 'tickets':
      return Math.floor(id / 10)
    case 'parkings':
      return Math.floor(id / 10)
    case 'shop':
      return Math.floor(id / 10)
    case 'deal':
      return Math.floor(id / 10)
    default:
      return id
  } 
}

// Add Postfixes to the cart ID's - End

export function hasExtraFields(items){
  let has = false
  items.forEach((item)=>{
    if(item?.extraFields?.length > 0) has = true
  })
  return has
}

export function getNonEmptyFields(obj){
    return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
}

export function parseUrlParams(obj){
    return Object.fromEntries([...obj])
}

export function getObjectToParams(obj){
    let value = []
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            value.push(key+'='+obj[key])
        }
    }
    return value.join('&')
}

export function setAllFieldsEmpty(obj){
    let value = {}
    for (let key in obj){
        value[key] = ''
    }
    return value
}

export const sliderUp = () => {
    const slider = document.getElementById("slideSearch");
    if (slider) {
      slider.style.transition = "all 0.5s ease";
      slider.style.transform = "translateY(0%)";
      
    }
  };

  export const sliderDown = () => {
    const slider = document.getElementById("slideSearch");
    if (slider) {
      slider.style.transition = "all 0.5s ease";
      slider.style.transform = "translateY(100%)";
      
    }
  };

  export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  export const getExtrafieldsFilteredOrder = (event,extraFields,email,cartTotal) => {
    let tickets = []
    let parking = []
    let shopitems = []
    let deals = []

    extraFields.forEach((item)=>{
      switch (item.type) {
        case 'tickets':{
            const {type,...otherFields} = item
            tickets.push({...otherFields,id: removeIdPostFix(item.id,item.type)})
            break;
        }
        case 'parkings':{
            const {type,...otherFields} = item
            parking.push({...otherFields,id: removeIdPostFix(item.id,item.type)})
            break;
        }
        case 'shop':
          const {type,...otherFields} = item
          shopitems.push({...otherFields, id: removeIdPostFix(item.id,item.type)})
            // shopitems.push({
            //     id: removeIdPostFix(item.id,item.type),
            //     name: item.name,
            //     qty: item.quantity,
            //     price: item.itemTotal
            // })
            break;
    
        default:
            break;
    }
    })

    const order = {
      email: email ? email : null,
      eventId : event.id,
      totalBill: cartTotal,
      tickets,
      parking,
      shopitems,
      deals
  }
    return order
  }

  export const getFilteredOrder = (event,items,email,cartTotal) => {
    let tickets = []
    let parking = []
    let shopitems = []
    let deals = []

    items.forEach((item)=>{
        switch (item.type) {
            case 'tickets':
                tickets.push({
                    id: removeIdPostFix(item.id,item.type),
                    name: item.name,
                    qty: item.quantity,
                    price: item.itemTotal,
                })
                break;
            case 'parkings':
              
                parking.push({
                    id: removeIdPostFix(item.id,item.type),
                    name: item.name,
                    qty: item.quantity,
                    price: item.itemTotal
                })
                break;
            case 'shop':
              console.log("Removing postfix",item.id,removeIdPostFix(item.id,item.type))
                shopitems.push({
                    id: removeIdPostFix(item.id,item.type),
                    name: item.name,
                    qty: item.quantity,
                    price: item.itemTotal
                })
                break;
        
            default:
                break;
        }
    })

    const order = {
        email: email ? email : null,
        eventId : event.id,
        totalBill: cartTotal,
        tickets,
        parking,
        shopitems,
        deals
    }

    return order
  }

export const downloadTicket = async (elem,number)=>{
  try {

    const dataUrl = await htmlToImage.toPng(elem,{
      style:{
        fontFamily:'sans-serif'
      }})
    // download image
    const link = document.createElement('a');
    link.download = 'ticket#'+number+'.png';
    link.href = dataUrl;
    link.click();
    return Promise.resolve(true)
  } catch (error) {
    console.log("error>>",error)
    return Promise.reject(false)
  }
}

export const getAddress = function(event){
  let addressString = event?.address;
  if(event?.city) addressString += ', '+event?.city;
  if(event?.state) addressString += ', '+event?.state;
  if(event?.country) addressString += ', '+event?.country;
  return addressString
}

export const getTodayDate = function(){
  return moment(new Date()).format('MM/DD/YYYY')
}

export const getDaysRange = function(days){
  const today = new Date();
  // ✅ Get the first day of the current week (Sunday)
  let firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
  firstDay = moment(firstDay).format('MM/DD/YYYY')
  // ✅ Get the last day of the current week (Saturday)
  let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + (days - 1)));
  lastDay = moment(lastDay).format('MM/DD/YYYY')

  return {
    firstDay,
    lastDay
  }
}

export const getImgSrcs = (src)=>{
  if(!src) return null
  let w256 = src.replace('upload/','upload/f_auto,w_256/')
  let w512 = src.replace('upload/','upload/f_auto,w_512/')
  let w768 = src.replace('upload/','upload/f_auto,w_786/')
  let w1024 = src.replace('upload/','upload/f_auto,w_1024/')
  let w1280 = src.replace('upload/','upload/f_auto,w_1280/')
  let srcset = w256 + ' 256w,' + w512 + ' 512w,' + w768 + ' 768w,' + w1024 + ' 1024w,' + w1280 + ' 1280w'
  src = w512
  return {srcset,src}
}

export const getImgSets = (src,type)=>{
  if(!src) return null
  let w750 = src.replace('upload/','upload/f_auto,w_750/')
  let w2048 = src.replace('upload/','upload/f_auto,w_2048/')
  if(type === 'width'){
    let srcset = "image-set(url('" + w750 + "') 750w, url('" + w2048 + "') 2048w)"
    src = "url("+w750+")"
    return {srcset,src}
  }else{
    let srcset = "url(" + w750 + ") 1x, url(" + w2048 + ") 2x"
    src = "url("+ w750 + ")"
    return {srcset,src}
  }
}

export const calculateFees = function(items,fee){
  let totalFee = 0, shopItemsFee = 0;
  items.forEach((item)=>{
    if(item.itemTotal === 0) return;
    if(item.type === 'shop'){
      shopItemsFee = fee;
      totalFee += ((5  * item.itemTotal) / 100)
    }else{
      totalFee += item.quantity * fee
      totalFee += ((5  * item.itemTotal) / 100)
    }
  })
  return parseFloat(totalFee.toFixed(2)) + shopItemsFee
}

export const sendTickets = async function(orderId){
  Swal.fire({
    title: 'Send receipt to the mail!',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
    showLoaderOnConfirm: true,
    confirmButtonColor: '#F85431',
    preConfirm:async (email) => {
        try {
            const {data} = await sendTheReceipt(orderId,email)
            return data
        } catch (error) {
            const { response } = error
            const {status,data} = response
            if(status === 400){
                Swal.showValidationMessage(
                    data?.errors?.email
                )    
            }else{
                Swal.showValidationMessage(
                    `Request failed: ${error}`
                )
            }
        }
    },
    allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        console.log("Result",result)
    if (result.isConfirmed) {
        Swal.fire({
            icon: 'success',
            title: 'Mail sent!',
            text: result.value.message,

        })
    }
    })
}