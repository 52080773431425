import { Routes, Route, Navigate } from 'react-router-dom';
import 'lazysizes'

import HeaderPrimery from './Common/HeaderPrimery';
import { useLocation } from "react-router-dom";
import React, { Suspense, useContext } from 'react';
import { SimpleToaster } from './helpers/toasters';
import AppContext from './store/AppContext';
import SectionLoader from './Common/Loaders/SectionLoader';

import Index from './Components/Home/Index';

const SignIn = React.lazy(()=>import('./Components/Accounts/SignIn'));
const Footer = React.lazy(()=>import('./Common/Footer'));
const IndexBookTicket = React.lazy(()=>import('./Components/BookTicket/IndexBookTicket'));
const Transactions = React.lazy(()=>import('./Components/Transactions/Index'))
const TransactionDetails = React.lazy(()=>import('./Components/Transactions/TransactionDetails'))

const PageNotFound = React.lazy(()=>import('./Common/PageNotFound')) ;

const LayoutForLoggedIn = React.lazy(()=>import('./Components/Accounts/layout/ForLoggedIn'))
const LayoutForLoggedOut = React.lazy(()=>import('./Components/Accounts/layout/ForLoggedOut'))

function App() {
    const {toaster} = useContext(AppContext)
    const location = useLocation();
    const path = location.pathname;

    document.addEventListener('lazybeforeunveil', function(e:any){
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

    return (
    <div className="App" data-page={path}>
         <HeaderPrimery />
         <div className="page--templates">
            <Suspense fallback={<SectionLoader/>}>
            <Routes>
                <Route element={<LayoutForLoggedOut/>}>
                    <Route path='/' element={<Index />} />
                    <Route path='/events/:event_id/book-ticket' element={<IndexBookTicket/>} />
                    <Route path='/events/:event_id/transactions' element={<Transactions />} />
                    <Route path='/events/:event_id/transactions/:order_id' element={<TransactionDetails/>} />
                    <Route path="*" element={<Navigate to="/pages/404" />}/>
                    <Route path="/pages/404" element={<PageNotFound/>}/>
                </Route>
                <Route path='/account/' element={<LayoutForLoggedIn/>}>
                    <Route path="signin" element={<SignIn/>}/>
                </Route>
            </Routes>
            </Suspense>
            </div>
            <Footer />
            <SimpleToaster open={toaster.open} type={toaster.type} message={toaster.message}/>
        </div>
    );
}

export default App;
