import React from 'react';
import { Sidebar } from './HeaderPrimery';
import { NavLink} from "react-router-dom";
import styled from '@emotion/styled';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import {Avatar, Box} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppContext from "../store/AppContext";
import { useContext } from "react";



const ListDivider = styled(Divider)({
  width: '100%',
  bgcolor: 'gray',
})

const SidebarNav =  styled('div')({
   "& .sidebar__navigation":{
     display:'flex',
     alignItems: 'center',
     justifyContent: 'space-between',
     flexDirection: 'column',
     color: '#000',
     "& .nav-list":{
       margin: '0'
     },
     "& .collp":{
      display:'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
     },
     "& .profile-btn,.collp":{
      width: '100%',
      "& .pt, .pt span":{
        fontFamily: 'var(--primaryFont)'
      },
      "& ul":{
        height: '100% !important',
        padding: '10px 1em',
        "& li":{
          padding: '5px 0px'
        }
      }
     }
   }
})

const SocialLinks = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1em',
  fontFamily: 'var(--primaryFont)',
  color: 'var(--colorThemePrimary)'
})

  
  interface Props {
    isOpen: boolean;
    close: () => void;
  }
  const HeaderSidebar: React.FC<Props> = ({ close }) => {
    const {user,logout} = useContext(AppContext);
    const handleLogout = () => {
      logout()
    }

    return (
      <Sidebar id="sidebarMenu" >
        <SidebarNav className="overlay">
          <nav className="sidebar__navigation" onClick={(event) => event.stopPropagation()}>
            <ul className="nav-list">
                
                <li className="nav-list-item"  onClick={close}>
                  <NavLink to="/" className="sidebar-link nav--link">Home</NavLink>
                </li>
            </ul>
            
            { user && 
            <>
            <ListDivider />
            <Box className="collp">
              <ListItemButton className="profile-btn" onClick={close}>
                
                  <ListItemIcon>
                    <Avatar src={user.userImageURL ? (user.userImageURL.split(';').pop()) : ''}>
                      {user?.email.charAt(0).toUpperCase()}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText className="pt" primary={user?.email}/>
                
              </ListItemButton>
              <ul className="nav-list">
                  <li className="nav-list-item"  onClick={close}>
                    <NavLink to="/" onClick={()=>handleLogout()} className="sidebar-link nav--link">Logout</NavLink>
                  </li>
              </ul>
            </Box> 
            </>
            }
          </nav>
          <div className="closebtn" onClick={close}>
            <CloseIcon className="icon icon-close"/>
          </div>
        </SidebarNav>
      </Sidebar>
    );
  };
  

  
  
export default HeaderSidebar