import React from 'react'
import AppContext from "../store/AppContext";
import { Logout } from "@mui/icons-material";
import { Avatar, Box, IconButton, styled } from "@mui/material";
import { useContext } from "react";
import { MenuItem } from './HeaderPrimery';
import { Link,} from "react-router-dom";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


const DropDiv = styled('div')({
  position: 'relative',
  "&:hover":{
    "& .dd-menu":{
      display: 'block'
    }
  },
  "& .dd-menu":{
    backgroundColor: '#fff',
    color: '#000',
    position: 'absolute',
    top: '40px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    right: '0',
    zIndex: '12',
    fontFamily: 'var(--primaryFont)',
    borderRadius: '4px',
    minWidth: '170px',
    display: 'none'
  },
  "& .profile--icon-wrap":{
     "& .img-icon":{
       width: '32px',
       height: '32px'
     }
  }
})

const ProfileBox = styled('div')({
  cursor: 'pointer',
  color: '#000',
  padding: '10px',
  textAlign: 'left',
    "& .dd-menu-item":{
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      margin: '0',
      cursor: 'pointer',
      padding: '5px',
      fontFamily: 'var(--primaryFont)',
      fontWeigth: '400',
      "& > a":{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'flex-start',
        margin: '0',
        gap: '0.3em', 
        fontFamily: 'var(--primaryFont)',
        fontSize: '16px',
        fontWeigth: '400',
        "& span":{
          fontWeight: '400'
        },
        "& svg":{
          width: '20px',
          height: '20px',
          "& path[opacity]":{
            fill: 'white'
          }
        }, 
        "&:hover":{
          "& svg":{
            fill: 'var(--colorThemePrimary)',
          }
        }
      }
    },
    "& .hr":{
      marginTop: '8px',
      marginBottom: '8px',
      borderWidth: '0',
      borderBottomWidth: 'thin',
      borderStyle: 'solid',
      borderColor: 'rgba(0,0,0,0.12)'
    }
})



const ProfileIcon = () => {
  const {user,logout} = useContext(AppContext);
  const handleLogout = () => {
        logout()
  }
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if(anchorEl){
      setAnchorEl(false);
    }else{
      setAnchorEl(true);
    }
    
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  
  return (
    <DropDiv>
       <Box className="profile--icon-wrap">
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar className="img-icon" 
                        src={user.userImageURL ? (user.userImageURL.split(';').pop()) : ''}>
                  {user?.email.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>
        </Box>
        <ProfileBox id="basic-menu" className="dd-menu">
            <div className="menu-wrapper">
              <MenuItem onClick={handleClose} className="dd-menu-item">
                <Link to="#">
                  <span>{user?.email}</span>
                </Link>
              </MenuItem>
              <hr className='hr'/>
              <MenuItem onClick={handleClose} className="dd-menu-item">
                <Link to="" onClick={()=>handleLogout()}>
                  <Logout className="icon icon-logout" />
                  <span>Log Out</span>
                </Link>
              </MenuItem>
            </div>
        </ProfileBox>
    </DropDiv>
  )
}

export default ProfileIcon