import React from 'react';
import logo from '../../assets/images/logo-black.png';


const ORG_SCHEMA = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Eazyticks - Online E-ticketing Booking Platform",
    "description": "Eazyticks is an Online E-ticketing ticketing platform, easy-to-use and with the lowest ticket booking fees for event creation and selling.",
    "url": `${process.env.REACT_APP_API_URL}`,
    "logo": `${process.env.REACT_APP_API_URL}${logo}`,
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Baton Rouge",
        "addressLocality": "Lousiana",
        "addressRegion": "United States",
        "postalCode": "45832",
        "Telephone": "+1(202)4365839"
    },
    "sameAs": [
        "https://instagram.com/eazyticks?utm_medium=copy_link",
        "https://twitter.com/EazyTicks",
        "https://www.facebook.com/profile.php?id=100087118817848&mibextid=ZbWKwL"
    ]
  });


const Json = () => {
  return (
    <>
    <script type="application/ld+json" dangerouslySetInnerHTML={ { __html: ORG_SCHEMA} }/>
    </>
  )
}

export default Json