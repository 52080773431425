import React from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';


const Loader = styled(Box)({
   maxWidth: '300px',
   padding: '2em 1em',
   display:'flex',
   alignItems: 'center',
   justifyContent: 'center',
   margin: 'auto',
   "& .load":{
    backgroundColor: 'var(--colorThemeSecondary)',
    widht: '100px',
    height: '100px',
    background: 'transparent',
    "& svg":{
      color: 'var(--colorThemePrimary)',
      fill: 'var(--colorThemePrimary)'
    }
   }
})

function SectionLoader() {
  return (
    <Loader>
      <CircularProgress className='load' style={{width: '100px',height:'100px'}} />
    </Loader>
  )
}

export default SectionLoader