import axiosAuthRequest from "./axiosAuthRequest";
import axios from "axios";

export async function getHomePageData(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/Event')
}

export async function getOrganizer(id,sort){
    return await axiosAuthRequest(process.env.REACT_APP_API_URL+`/api/Event/profile/?Id=${id}&OrderBy=${sort}`)
}

export async function FollowOrganizer(orgId){
    return await axiosAuthRequest(process.env.REACT_APP_API_URL + '/api/followorganizer/follow?orgId='+orgId);
}

export async function UnfollowOrganizer(orgId){
    return await axiosAuthRequest(process.env.REACT_APP_API_URL + '/api/followorganizer/unfollow?orgId='+orgId);
}

export async function LikeEvent(eventSlug){
    return await axiosAuthRequest(process.env.REACT_APP_API_URL + '/api/wishlist/add?eventSlug='+eventSlug);
}

export async function UnlikeEvent(eventSlug){
    return await axiosAuthRequest(process.env.REACT_APP_API_URL + '/api/wishlist/remove?eventSlug='+eventSlug);
}

export async function getCategory(name,page=0,take=10,sort='A-Z',q){
    return await axios.get(process.env.REACT_APP_API_URL+"/api/Event/category?q="+q+"&cateogryName="+name+"&skip="+page+"&take="+take+"&sort="+sort);
}

export async function getTodayEvents(){
    return await axios.get(process.env.REACT_APP_API_URL + '/api/event/today')
}

export async function getWeekendEvents(){
    return await axios.get(process.env.REACT_APP_API_URL + '/api/event/weekend')
}

export async function getEventDetails(slug,secret){
    let params = '';
    if(secret) params += '?secret=' + secret
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + "/api/Event/"+slug+"/Detail"+params)
}

export async function getActiveEvents(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/Event');
}

export async function getEventTickets(event_id){
    let params = ['eventId='+event_id];
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/Event/tickets?'+params.join('&'))
}

export async function getLiveSearch(params){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/search/live?'+params)
}

export async function getPopularSearches(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/popularsearch?take=5')
}

// Checkout - Start

export async function placeTheOrder(order){
    return await axiosAuthRequest.post(process.env.REACT_APP_API_URL + '/api/pos',order)
}

export async function getTheOrder(paymentIntentId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/pos/order?paymentIntentId='+paymentIntentId)
}

export async function getOrderDetails(orderId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/pos/order/details?OrderId='+orderId);
}

export async function getClientSecret(orderId){
    return await axios.get(process.env.REACT_APP_API_URL + '/api/checkout/clientsecret?orderId='+orderId)
}

export async function completeTheOrder(paymentIntentId,orderId){
    return await axios.post(process.env.REACT_APP_API_URL + '/api/checkout/complete?paymentIntentId='+paymentIntentId+'&id='+orderId)
}

export async function cancelTheOrder(orderId,terminalId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL+'/api/pos/order/cancel?paymentIntentId='+orderId+'&terminalId='+terminalId)
}

export async function sendTheReceipt(orderId,email){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL+'/api/pos/sendreceipt?paymentIntentId='+orderId+'&email='+email)
}

export async function checkOrderStatus(paymentIntentId,terminalId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/pos/checkstatus?paymentIntentId=' + paymentIntentId + '&terminalId=' + terminalId)
}

export async function regenrateOrder(orderId,terminalId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/pos/order/regenratepayment?paymentIntentId='+orderId+'&terminalId='+terminalId)
}



// Checkout - End

// Terminal - Start

export async function getTerminal(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/terminal');
}

// Terminal - End

// Accounts - Start

export async function LoginUser(email,password){
    return await axios.post(process.env.REACT_APP_API_URL + '/api/Auth/Login',{
        email,
        password
    })
}

export async function RegisterUser(email,password,confirmPassword){
    return await axios.post(process.env.REACT_APP_API_URL + '/api/Auth/Register',{
        email,
        password,
        confirmPassword
    })
}

export async function getOrderTickets(id){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/ticket/detail/'+id)
}

export async function getOrderTicketsNonLoggedIn(id,sec){
    return await axios.get(process.env.REACT_APP_API_URL + '/api/ticket/myticket?id='+id+'&sec='+sec)
}

export async function getUserNotifications(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/auth/userinfo/notification')
}


export async function getEventOrders(eventId){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/pos/orders?eventId='+eventId)
}

export async function getUserProfile(){
    return await axiosAuthRequest.get(process.env.REACT_APP_API_URL + '/api/auth/userinfo')
}



// Accounts - End


export async function newsletterSubscribe(token,email){
    return await axios.post(process.env.REACT_APP_API_URL + '/api/newsletter?token='+token+'&email='+email)
}

export async function getAllEvents(){
    return await  axios.get(process.env.REACT_APP_API_URL + '/api/event/selectevent')
}

export async function findTickets(email,eventId){
    return await axios.get(process.env.REACT_APP_API_URL + '/api/ticket/find?email='+email+"&eventId="+eventId)
}
