
import React from 'react';
import { Helmet } from 'react-helmet-async';
import logo from '../assets/images/logo-black.png'

interface FunctionProps{
  title?:any,
  description?: any,
  url?:any,
  image?:any,
  index?:Boolean
}

const MetaTags = ( {title,description,url,image,index}:FunctionProps ) => {
  let shopName = 'EazyTicks - E-ticketing Booking Platform';
  let shopDescription = 'EazyTicks is an Online E-ticketing platform, easy-to-use and with the lowest ticket booking fees for event creation and selling.';
  let shopUrl = `${process.env.REACT_APP_SITE_URL}`;
  let shopImage = `${process.env.REACT_APP_SITE_URL}${logo}`;
  return (
    <Helmet>
        { /* Standard metadata tags */ }
        <title>{title ? title : shopName}</title>
        <meta name="title" content={title ? title : shopName}/>
        <meta name='description' content={description ? description : shopDescription } />
        <link rel="canonical" href={url ? shopUrl + url : shopUrl}/>
        <meta name="robots" content="noindex, nofollow"/>
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:site_name" content='Eazyticks'/>
        <meta property="og:url" content={url ?  shopUrl + url : shopUrl}/>
        <meta property="og:title" content={title ? title : shopName}/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content={description ? description : shopDescription }/>
        { /* End Facebook tags */ }
        {/* Page Image */}
        <meta property="og:image" content={image ? image : shopImage }/>
        <meta property="og:image:secure_url" content={image ? image : shopImage }/>
        {/* Page Image End Here */}
        { /* Twitter tags */ }
        <meta name="twitter:site" content="@EazyTicks"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={title ? title : shopName }/>
        <meta name="twitter:description" content={description ? description : shopDescription }/>
        { /* End Twitter tags */ }
    </Helmet>
  )
}

export default MetaTags
