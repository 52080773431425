import React,{useState} from "react";
import { styled } from "@mui/system";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAddress, getImgSrcs } from "../../../helpers/generals";


const EventCardStyleSearchPage = styled('div')({
  borderRadius: "8px",
  background: 'white', 
  cursor: 'pointer',
  marginBottom: '1em',
  boxShadow: "0 0 1px 1px rgba(0, 0, 0, 0.3)",
  "& .sc-main":{
    display: "flex",
    "& .event-info":{
      position: 'relative',
      width: '100%',
      flexBasis: '65%',
      "& > *":{
        margin: '0px 0px 15px 0px'
      },
      "& .like-wrapper":{
        color: 'var(--colorThemePrimary)',
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '5px',
        cursor: 'pointer',
        zIndex: '5',
        "& .icon , svg , .icon-loading path":{
          fill: 'var(--colorThemePrimary)'
        }
      }
    },
    "& .image--wrapper":{
      flexBasis: '35%',
      borderRadius: '8px 0px 0px 8px',
      "@media(max-width:768px)":{
        borderRadius: '8px 8px 0px 0px',
      },
      "& .card_media_wrapper":{
        position: 'relative',
        height: '230px',
        zIndex: '0',
        overflow: 'hidden',
        borderRadius: '8px 0px 0px 8px',
        "@media(max-width:768px)":{
          borderRadius: '8px 8px 0px 0px',
        },
        "& .overlay":{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%',
            position: 'absolute',
            inset: '0',
            width: '100%',
            height: '100%',
            zIndex: '1',
            borderRadius: '8px 0px 0px 8px',
            filter: 'blur(50px) brightness(.9)',
            "@media(max-width:768px)":{
              borderRadius: '8px 8px 0px 0px',
            },
        },
        "& img":{
            margin: 'auto',
            objectFit: 'contain',
            position: 'absolute',
            inset: '0',
            width: '100%',
            height: '100%',
            zIndex: '1',
            borderRadius: '8px 0px 0px 8px',
            objectPosition: 'center center',
            "@media(max-width:768px)":{
              borderRadius: '8px 8px 0px 0px',
            },
        }
      },
    },
    "@media (max-width: 768px)": {
      flexDirection:"column",
      width: "100%",
      margin: "0px",
      justifyContent:"center",
      "& .event-info":{
        position: 'relative',
        width: '100%',
        flexBasis: '100%'
      },
      "& .image--wrapper":{
        flexBasis: '100%'
      },
    }
  },
  "&:hover": {
    background: "#fbfbfb",
    boxShadow: "0 0 1px 1px rgba(0, 0, 0, 0.3)"
  },
  
});

const EventHeadingSearchPage = styled("div")({
  color: "#000000",
  fontSize: "30px",
  fontWeight: "700",
  textAlign: "left",
  fontFamily: 'var(--primaryFont)',
  "@media (max-width: 576px)": {
    fontSize: "24px",
},

  
});
const ListItemTextStyleSearchPage1 = styled("div")({
  display: "flex",
  fontWeight: 600,
  fontSize: "20px",
  textTransform: "capitalize",
  color: "#F85431",
  "&.price":{
    fontSize: '16px',
    color: 'grey'
  }
});
const ListItemTextStyleSearchPage2 = styled('div')({
  display: "flex",
  fontWeight: 400,
  maxWidth: "400px",
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#212121",
});

interface FunctionProps{
  event:any
}

const EventCard = ({event}:FunctionProps) => {
  const renderImage = function(image:any){
    return (image ? image?.split(';')?.pop() : 'https://res.cloudinary.com/ddkdvh5ps/image/upload/v1683022134/ezt-frontend-client-assets/placeholder-image_rkxh0y.png' ) 
  }
  const renderMedia = function(image:any){
    if(image){
        const {src,srcset}:any = getImgSrcs(image.split(';')?.pop());
        return <img
          className="lazyload"
          sizes="(min-width: 30em) 28em, 100vw"
          data-src={src}
          data-srcset={srcset}
          alt={event?.name ? event?.name + ' - Eazyticks' : 'Event - Eazyticks'}
          loading="lazy"/>;
    }else{
        const {src,srcset}:any = getImgSrcs('https://res.cloudinary.com/ddkdvh5ps/image/upload/v1683022134/ezt-frontend-client-assets/placeholder-image_rkxh0y.png');
        return <img
          className="lazyload"
          sizes="(min-width: 30em) 28em, 100vw"
          data-src={src}
          data-srcset={srcset}
          alt={event?.name ? event?.name + ' - Eazyticks' : 'Event - Eazyticks'}
          loading="lazy"/>;
    }
  }
  return (
    <EventCardStyleSearchPage>
      <div className="sc-main">
      <div className="image--wrapper">
        <Link to={"/events/"+event?.id+"/book-ticket"} >
        <div className='card_media_wrapper' >
            <div className="overlay lazyload" data-bg={renderImage(event?.image)}></div>
            {renderMedia(event?.image)}
        </div>
        </Link>
      </div>
      <CardContent className="event-info">
        <Link to={"/events/"+event?.id+"/book-ticket"} >
        <EventHeadingSearchPage className="two-lines">{event?.name}</EventHeadingSearchPage>
        <Box>
          {/* <ListItemTextStyleSearchPage1 className="price">
            {event?.startPrice == 0 ? 'Free' : `Starts at $${event?.startPrice}` }  
          </ListItemTextStyleSearchPage1> */}
          <ListItemTextStyleSearchPage1>
            Starting on {moment(event?.startDate).format('MMMM Do YYYY, h:mm a')}  
          </ListItemTextStyleSearchPage1>
          <ListItemTextStyleSearchPage2>
            {getAddress(event)}
          </ListItemTextStyleSearchPage2>
        </Box>
        </Link>
      </CardContent>
      </div>
    </EventCardStyleSearchPage>
  );
};

export default EventCard;
