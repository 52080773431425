import React, { useContext } from "react";
import { Link} from "react-router-dom";
import {  Box, Grid, styled, Typography } from "@mui/material";
import logo from "../logo-black.svg";
import { HiOutlineBars3 } from "react-icons/hi2";
import HeaderSidebar from "./HeaderSidebar";
import {useState} from "react";
import Json from "./jsonld/Json";
import AppContext from "../store/AppContext";
import ProfileIcon from "./ProfileIcon";
import SearchIcon from '@mui/icons-material/Search';
import { getDaysRange, sliderUp } from "../helpers/generals";



export const ButtonWrapper = styled("div")({
  "& .side-bar-button__header": {
    display: "none",
    "@media (max-width: 768px)": {
      display: "flex",
    },
  },
});
export const HeaderWrapper = styled(Box)({
  width: "100%",
  backgroundColor: 'var(--primaryHeaderBgclr)',
  color: 'var(--primaryHeaderTextclr)',
  zIndex: '2',
  "&.secondary-wrapper":{
    backgroundColor: 'var(--secondaryHeaderBgclr)',
    color: 'var(--secondaryHeaderTextclr)',
    position: 'static',
    top: 'unset',
    zIndex: '1',
    "& > *":{
      color: 'var(--secondaryHeaderTextclr)',
    }
  },
  "& #slide":{
    transform:"translateX(100%)"
  },
  "& .main__header-container":{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    maxWidth: "1300px",
    margin: "0 auto",
    padding: "1em",
    "@media(max-width:768px)":{
      justifyContent: 'space-between'
    },
    "& .header-logo":{
      textAlign: "left" 
    },
    "& .header_item_primary":{
      "& .header--nav":{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .header--nav-item":{
          color: 'inherit',
          "& a":{
            color: 'inherit',
            "&:hover":{
              color: 'inherit'
            }
          },
          "&:hover":{
            color: 'inherit'
          }
        }
      }
    },
    "& .header_item":{
       textAlign: 'right',
       "& .right--navigation":{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          "@media(max-width:768px)":{
             "& .create-event,.login-btn":{
              display: 'none'
             }
          }
       }
    }
  },
  "@media (max-width:768px)": {
    background: "",
  },
  "& .header_item_primary": {
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  "& .header_item_secondary": {
    "@media (max-width:768px)": {
      background: "",
    },
  },
  "& .header-logo": {
    "@media (max-width:768px)": {
      "& .header-logo-wrapper":{
        maxWidth: '150px',
        display: 'flex'
      }
    },
  },
  "& .search-field-container":{
    display:"",
    "@media (max-width:768px)": {
      display:"none"
    },
  },
  "& .logo-img": {
    marginRight: "2rem",
  },
});

export const MenuItem = styled(Typography)({
  marginRight: "15px",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "20.14px",
  fontStyle: "normal",
  lineHeight: "20.14px",
  letterSpacing: "0.43px",
  color: 'inherit',
  "& a": {
    color: "inherit",
    "&:hover": {
      color: "inherit",
      opacity: "0.8",
    },
  },
});



export const MenuItemSecondary = styled('span')({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18.8041px",
  lineHeight: "19px",
  letterSpacing: "0.352577px",
  marginLeft: "20px",
  color: 'inherit',
  "& a": {
    color: "inherit",
    "&:hover": {
      color: "inherit !important",
      opacity: "0.8",
    },
  },
  "@media (max-width: 576px)": {
    marginLeft: "10px",
    fontSize: "11px",
  },
  "& .search-field-container":{
    display: 'none',
    "@media(max-width:768px)":{
      display: 'block'
    }
  }
});

export const CreateEventButton = styled(Typography)({
  fontFamily: "Poppins",
  position: "relative",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18.8041px",
  lineHeight: "19px",
  letterSpacing: "0.352577px",
  marginLeft: "20px",
  color: 'inherit',
  cursor: 'cell',
  "@media (max-width: 576px)": {
    fontSize: "11px",
  },
});

export const Sidebar = styled(Box)({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  height: '100%',
  transition: "all 0.5s ease !important",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#F5F5F5',
  zIndex: 5,
  cursor: 'pointer',
  "& .overlay": {
    position: 'fixed',
    width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 5,
    '& nav': {
      fontFamily: 'var(--primaryFont)',
      fontSize: '16px',
      fontWeight: '500',
      position: 'relative',
      height: '100%',
      width:'85%',
      marginLeft: 'auto',
      background: '#FFFFFF',
      '& .sidebar-footer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        padding: '1em',
        borderTop: '1px solid #A8A8A8',
        backgroundColor: '#FFFFFF',
        color: 'var(--colorThemePrimary)'
      },
      '& ul': {
        height: 'calc(100% - 100px)',
        width: '100%',
        listStyle: 'none',
        padding: '1em',
        overflowY: 'auto',
        textAlign:"right",
        '& li': {
          padding: '10px 12px'
          },
         '& a': {
            position: 'relative',
            color: 'black',
            textDecoration: 'none',
            fontSize: '18px',
            // paddingLeft: '20px',
            // paddingRight: "60px",
            fontWeight: '500',
            display: 'block',
            width: '100%',
            textAlign: "left",
            '& span': {
              position: 'absolute',
              top: '50%',
              right: '20px',
              transform: 'translateY(-50%)',
              fontSize: '20px',
              transition: 'transform 0.4s',
            },
          },
          '& a:hover': {
            background: 'rgba(0, 0, 0, .1)',

          },
          '& a.click span::before': {
            content: '"\f068"',
          },
        },
      },
    },
    "& .closebtn": {
      // position: "static",
      border: "none",
      background: 'var(--colorThemePrimary)',
      height: "100%",
      width: "15%",
      padding: "10px",
      position:"absolute",
      right:"85%",
      top:'0',
      "& .icon": {
        color: "#fff",
        fontSize: "35px", 
        textStroke: '1px black',
      }
    }
})



function HeaderPrimery() {
  const {user,logout} = useContext(AppContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const mainBody = document.querySelector('body');
    const openSidebar = () => {
      const sidebarMenu = document.getElementById("slide");
      const body = document.querySelector("body");
      if (sidebarMenu){
        sidebarMenu.style.transition = "all 0.9s ease";
        sidebarMenu.style.transform = "translateX(0%)";
        if(body){
          body.style.overflow = 'hidden';
        }
        
        if(mainBody){mainBody.style.overflow = "hidden";}
        setIsSidebarOpen(true);
      }
    }


  const closeSidebar = () => {
    const sidebarMenu = document.getElementById("slide");
    const body = document.querySelector("body");
    if(sidebarMenu){
      sidebarMenu.style.transition = "all 0.9s ease";
      sidebarMenu.style.transform = "translateX(100%)";
      if(body){
        body.style.overflow = 'auto';
      }
      if(mainBody){mainBody.style.overflow = "auto";}
      setIsSidebarOpen(true);
    }
  }

return (
    <header className="header primary--header">
      <HeaderWrapper>
      <Sidebar id="slide">
          {isSidebarOpen && <HeaderSidebar isOpen={isSidebarOpen} close={closeSidebar} />}
      </Sidebar>
       <Grid className="main__header-container" container>
            <Grid item sm={3} className="header-logo" >
              <Link to="/" className="header-logo-wrapper">
                <h1 className="visually-hidden">Eazyticks</h1>
                <img src={logo} 
                     className="logo-img" 
                     alt="Eazyticks offical Logo" loading="lazy"/>
              </Link>
            </Grid>
            <Grid item sm={6.3} className="header_item_primary">

            </Grid>
            <Grid item sm={2.7} className="header_item">
              <Box className="right--navigation">
                {user && <MenuItemSecondary className="header--nav-item login-btn">   
                  <ProfileIcon />
                </MenuItemSecondary>}
                <MenuItemSecondary>
                  <ButtonWrapper>
                    <HiOutlineBars3 size={30} 
                       className="side-bar-button__header header--nav-item" 
                       onClick={openSidebar}/>
                  </ButtonWrapper>
                </MenuItemSecondary>
              </Box>
            </Grid>
          </Grid>
      </HeaderWrapper>   
      <Json />
    </header>
  );
}

export default HeaderPrimery;
