import {HubConnectionBuilder,LogLevel} from '@microsoft/signalr'

async function getConnection(user){
    if(!user) return;
    try {
        const connection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/OrderNotificationHub',{
            accessTokenFactory: () => {
              return user.token
            }
          }).withAutomaticReconnect().configureLogging(LogLevel.Information).build();
          
          connection.onclose(e => {
            console.log("Is closing connection")
          })
          await connection.start();
          return connection
    } catch (error) {
        console.log("Error",error)
    }
}

async function stopConnection(connection){
    if(connection === null) return
    await connection?.stop();
}

export {
    getConnection,
    stopConnection
}