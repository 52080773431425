import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';
import { AppProvider } from './store/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from 'react-use-cart';
import { CookiesProvider } from 'react-cookie';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <CartProvider>
        <AppProvider>
          <HelmetProvider>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </HelmetProvider>
        </AppProvider>
      </CartProvider>
    </BrowserRouter>
);
