import { Snackbar,Alert } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "../store/AppContext";

export function SimpleToaster({open,type,message}) {

  const {setToaster} = useContext(AppContext)

  function handleClose(){
    setToaster(false,null,null)
  }

  return (
    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        {type === 'success' ? (
            <Alert severity="success" sx={{ width: '100%' }}>{message}</Alert>
        ) : (
            <Alert severity="error" sx={{ width: '100%' }}>{message}</Alert>
        )}
    </Snackbar>
  )
}