import React from 'react'
import {styled} from '@mui/material';

const Heading = styled('div')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  textAlign: 'left',
  color: '#121212',
  marginBottom: '1em',
  "@media(max-width:768px)":{
    fontSize: '26px'
  }
})

interface PropsTypes {
  children: any
}

const SectionHeading: React.FC<PropsTypes> = ({children}) => {
  return (
    <Heading className='section__heading'>{children}</Heading>
  )
}

export default SectionHeading