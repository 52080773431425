import React, { useEffect, useState } from 'react';
import MetaTags from '../../Common/MetaTags';
import { getActiveEvents } from '../../helpers/businessRequests';
import PageLoader from '../../Common/Loaders/PageLoader';
import EventListing from './EventListing';



function Index() {
    const [events, setEvents] = useState([])
    const [isLoading,setIsloading] = useState(true);
    const getData = async ()=>{
        try {
            const { data } = await getActiveEvents();
            console.log("Data>>>>>>>>>",data)
            setEvents(data);
            setIsloading(false)   
        } catch (error) {
            console.log("Error",error)
            setIsloading(false)
        }
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <div className='wrapper'>
            <MetaTags index={true}/>
            {isLoading ? (
			<PageLoader />
	    	) : (
            <>
            <EventListing items={events} isLoading={isLoading} />
            </>
            )}
        </div>
    )
}

export default Index